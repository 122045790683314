import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./Footer.css";
import { toArray } from "@components/Kotlin/KotlinUtils";
import packageJson from "@app/../package.json";
import { footer, menu } from "@core";
import useOutput from "../../../Utils/Hooks/useOutput";
import { useTranslation } from "react-i18next";
import { MULTITENANT_CONSTANTS, TENANT } from "../../../Resources/Multitenant/tenantConstants";

function Footer() {
  const outputFn = useOutput();
  const [arraySponsors, setArraySponsors] = useState([]);
  const [menuUrls, setMenuUrls] = useState({});
  const [supportEmail, setSupportEmail] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    let con = new footer.controller();
    let binding = con.bind(model, (o) => outputFn(o, output, footer));
    con.dispatch(footer.events.GetSponsors);

    return () => {
      con.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const model = (o) => {
    setArraySponsors(toArray(o.sponsors));
  };

  const output = () => {};

  useEffect(() => {
    const con = new menu.controller();
    let binding = con.bind(menuModel, (o) => outputFn(o, menuOutput, menu));

    return () => {
      con.unbind(binding);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuModel = (o) => {
    const formattedModelUrls = {};
    if (o.termsAndConditionsUrl) {
      formattedModelUrls.termsAndConditionsUrl = o.termsAndConditionsUrl;
    }

    if (o.privacyStatementUrl) {
      formattedModelUrls.privacyStatementUrl = o.privacyStatementUrl;
    }
    !!o.supportEmail && setSupportEmail(o.supportEmail);
    setMenuUrls(formattedModelUrls);
  };

  const menuOutput = () => {};

  return (
    <div className="Footer">
      <div className="FooterBody">
        {arraySponsors &&
          arraySponsors.map((a) => (
            <div key={a.logo} className="ImageDiv">
              <Link to={""} onClick={() => (a.companyUrl !== null ? window.open(a.companyUrl, "_blank") : undefined)}>
                <img className="ImageEurope" src={a.logo} alt={"logos"} />
              </Link>
            </div>
          ))}
        <span className="versionTag">{packageJson.version}</span>
      </div>
      {!!supportEmail && (
        <div className={"footerLink"}>
          <div>
            <a href={"mailTo:" + supportEmail} target={"_blank"}>
              {t("suport.contact")} {supportEmail}
            </a>
          </div>
        </div>
      )}

      <div className={"footerLink"}>
        {!!MULTITENANT_CONSTANTS[TENANT]?.urlFaqs && (
          <div>
            <a href={MULTITENANT_CONSTANTS[TENANT]?.urlFaqs} target={"_blank"}>
              {t("suport.faqs")}
            </a>
          </div>
        )}
        {menuUrls.termsAndConditionsUrl || !!MULTITENANT_CONSTANTS[TENANT]?.urlTermsAndConditions && (
          <div>
            <a href={menuUrls?.termsAndConditionsUrl ? menuUrls.termsAndConditionsUrl : MULTITENANT_CONSTANTS[TENANT]?.urlTermsAndConditions} target={"_blank"}>
              {t("legal.termsAndConditions")}
            </a>
          </div>
        )}
        {menuUrls.privacyStatementUrl || !!MULTITENANT_CONSTANTS[TENANT]?.urlPrivacyStatement && (
          <div>
            <a href={menuUrls?.privacyStatementUrl ? menuUrls.privacyStatementUrl : MULTITENANT_CONSTANTS[TENANT]?.urlPrivacyStatement} target={"_blank"}>
              {t("legal.privacyStatement")}
            </a>
          </div>
        )}
        <div>
          <a href="#" className="cky-banner-element">
            {t("suport.cookies")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
